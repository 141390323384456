import fakeProducts from './fake-products';
import environment from '../../../environment';
import {takeWhile} from 'rxjs/operators';
const HomePageComponent = {
  template: `
  <jlp-hero-carousel data-items="$ctrl.topCarouselItems"></jlp-hero-carousel>
  <jlp-static-carousel data-items="$ctrl.topCarousel"></jlp-static-carousel>


  <div class="row clearfix max-width" style="margin-top: 20px;" data-ng-if="$ctrl.daysOut.length">
    <div class="small-12 columns">
      <div>
        <h2><a style="color: unset;" href="/category/days-out">Days Out</a></h2>
      </div>
    </div>
  </div>
  <jlp-card-carousel class="no-duplicate" overflow-scroll="false" data-items="$ctrl.daysOut"></jlp-card-carousel>

  <div class="row clearfix max-width" style="margin-top: 20px;" data-ng-if="$ctrl.hero.length">
    <jlp-full-width-hero-component data-items="$ctrl.hero"></jlp-full-width-hero-component>
  </div>


  <div class="row clearfix max-width" style="margin-top: 20px;" data-ng-if="$ctrl.free.length">
    <div class="small-12 columns">
      <div>
        <h2><a style="color: unset;" href="/category/free">Free</a></h2>
      </div>
    </div>
  </div>
  <jlp-card-carousel class="no-duplicate" overflow-scroll="false" data-items="$ctrl.free"></jlp-card-carousel>

  <div class="row clearfix max-width" style="margin-top: 20px;" data-ng-if="$ctrl.foodDrink.length">
    <div class="small-12 columns">
      <div>
        <h2><a style="color: unset;" href="/category/food-drink">Food & Drink</a></h2>
      </div>
    </div>
  </div>
  <jlp-card-carousel class="no-duplicate" overflow-scroll="false" data-items="$ctrl.foodDrink"></jlp-card-carousel>

  <div class="row clearfix max-width" style="margin-top: 20px;" data-ng-if="$ctrl.firstMiniHero.length">
    <jlp-half-width-hero-component data-items="$ctrl.firstMiniHero"></jlp-half-width-hero-component>
  </div>

  <div class="row clearfix max-width" style="margin-top: 20px;" data-ng-if="$ctrl.healthWellbeing.length">
    <div class="small-12 columns">
      <div>
        <h2><a style="color: unset;" href="/category/health-wellbeing">Health & Wellbeing</a></h2>
      </div>
    </div>
  </div>
  <jlp-card-carousel class="no-duplicate" overflow-scroll="false" data-items="$ctrl.healthWellbeing"></jlp-card-carousel>

  <div class="row clearfix max-width" style="margin-top: 20px;" data-ng-if="$ctrl.holidaysTravel.length">
    <div class="small-12 columns">
      <div>
        <h2><a style="color: unset;" href="/category/holidays-travel">Holidays & Travel</a></h2>
      </div>
    </div>
  </div>
  <jlp-card-carousel class="no-duplicate" overflow-scroll="false" data-items="$ctrl.holidaysTravel"></jlp-card-carousel>

  <div class="row clearfix max-width" style="margin-top: 20px;" data-ng-if="$ctrl.homeUtilities.length">
    <div class="small-12 columns">
      <div>
        <h2><a style="color: unset;" href="/category/home-utilities">Home & Utilities</a></h2>
      </div>
    </div>
  </div>
  <jlp-card-carousel class="no-duplicate" overflow-scroll="false" data-items="$ctrl.homeUtilities"></jlp-card-carousel>

  <div class="row clearfix max-width" style="margin-top: 20px;" data-ng-if="$ctrl.insuranceFinance.length">
    <div class="small-12 columns">
      <div>
        <h2><a style="color: unset;" href="/category/insurance-financial">Insurance & Finance</a></h2>
      </div>
    </div>
  </div>
  <jlp-card-carousel class="no-duplicate" overflow-scroll="false" data-items="$ctrl.insuranceFinance"></jlp-card-carousel>

  <div class="row clearfix max-width" style="margin-top: 20px;" data-ng-if="$ctrl.secondMiniHero.length">
    <jlp-half-width-hero-component data-items="$ctrl.secondMiniHero"></jlp-half-width-hero-component>
  </div>
`,
  controller: HomePageController,
};

export default HomePageComponent;

HomePageController.$inject = ['$http', 'LeisureJsonApi', 'UserService'];

export function HomePageController($http, JsonApi, UserService) {
  const vm = this;
  vm.$onInit = () => {
    $http
      .get(`${environment.settings.registeredAwsUrl}/homepage`)
      .then(response => {
        JsonApi.handleResponse(response, data => placeResponse(data));
      });
  };

  function placeResponse(data) {
    UserService.$user.pipe(takeWhile(user => !user, true)).subscribe(user => {
      user && filterOffers(data);
    });
  }

  function filterOffers(data) {
    const {topCarousel, hero, firstMiniHero, secondMiniHero} = fakeProducts[0];
    vm.daysOut = retiredAccess(
      data.find(element => element.title === 'Days Out').items
    );
    vm.foodDrink = retiredAccess(
      data.find(element => element.title === 'Food & Drink').items
    );
    vm.free = retiredAccess(
      data.find(element => element.title === 'FREE').items
    );
    vm.healthWellbeing = retiredAccess(
      data.find(element => element.title === 'Health & Wellbeing').items
    );
    vm.holidaysTravel = retiredAccess(
      data.find(element => element.title === 'Holidays & Travel').items
    );
    vm.homeUtilities = retiredAccess(
      data.find(element => element.title === 'Home & Utilities').items
    );
    vm.insuranceFinance = retiredAccess(
      data.find(element => element.title === 'Insurance & Financial').items
    );
    vm.topCarouselItems = retiredAccess(
      data.find(element => element.slug === 'top-slider').items
    );
    vm.topCarousel = retiredAccess(topCarousel);
    vm.hero = retiredAccess(hero);
    vm.firstMiniHero = retiredAccess(firstMiniHero);
    vm.secondMiniHero = retiredAccess(secondMiniHero);
  }

  function retiredAccess(sliderItems) {
    const {retired: isRetired} = UserService.user;

    return isRetired
      ? sliderItems.sort((x, y) =>
          x.retired === y.retired ? 0 : x.retired ? -1 : 1
        )
      : sliderItems.filter(element => !element.retired);
  }
}
