config.$inject = ['$stateProvider', 'RoutesProvider'];

export default function config($stateProvider, RoutesProvider) {
  const routes = RoutesProvider.$get();

  $stateProvider
    .state('Page', routes['Page'])
    .state('Page.Home', routes['Page.Home'])

    .state('Page.Search', routes['Search'])
    .state('Page.Listing', routes['Listing'])
    .state('Page.Ballot', routes['Ballot'])

    .state('Page.Company', routes['Company'])
    .state('Page.Category', routes['Category'])

    .state('Page.Checkout', routes['Checkout'])
    .state('Page.Checkout.confirmed', routes['Checkout.confirmed'])
    .state('Page.Checkout.refused', routes['Checkout.refused'])

    .state('Page.OrderSummary', routes['Order.summary'])

    .state('Page.User', routes['User'])
    .state('Page.UserAddress', routes['UserAddress'])
    .state('Page.UserOrders', routes['UserOrders'])
    .state('Page.UserClubs', routes['UserClubs']);
}
