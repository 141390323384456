import '../src/scss/leisure.scss';
import './js/common/lib/layout';
import './js/common/lib/ready';

import * as angular from 'angular';

import AuthModule from './js/auth/auth-module';
import BookingModule from './js/booking/booking-module';
import CommonModule from './js/common/common-module';
import GuestModule from './js/leisure/guest/guest-module';
import UserModule from './js/leisure/user/user-module';

angular.module('PartnerChoice', [
  AuthModule.name,
  CommonModule.name,

  UserModule.name,
  GuestModule.name,

  BookingModule.name,
]);
